import React from 'react'
import styled from '@emotion/styled'
import Helmet from 'react-helmet'
import theme from '../../utils/theme'

import Layout from '../../components/Layout'
import Page from '../../components/ui/Page'
import SplitContainer from '../../components/ui/SplitContainer'

import theinsider from './mickael-tournier-262768-unsplash.jpg'
import charityimg from './benjamin-faust-8310-unsplash.jpg'

const { Title, Lead, Paragraph: P } = SplitContainer

const CounterGroups = styled.div`
  display: flex;
  flex-direction: column;
  ${theme.mq.md} {
    flex-direction: row;
  }
`

const CounterGroup = styled.div`
  width: 100%;
  ${theme.mq.md} {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
`

const Counter = styled.h1`
  margin-bottom: 0;
  font-size: 3rem;
  color: ${theme.colors.selection};
  ${theme.mq.md} {
    margin-bottom: 1rem;
  }
`

const Category = styled.h3`
  color: ${theme.colors.selection};
  font-size: 1.5rem;
  opacity: 0.5;
`

const AboutPage = () => (
  <Layout>
    <Page>
      <SplitContainer imageUrl={theinsider}>
        <Helmet title={`About Park City Insider`} />
        <Title>Meet the Insider</Title>
        <Lead>
          After a day of flailing down the mountain, I sat down at the bar and
          asked the bartender what was happening tonight. She mentioned several
          bars that might have live music but wasn’t quite sure of how to find
          the answer to my question.
        </Lead>
        <P>
          I started thinking not only about past trips but in general, I rarely
          got a good answer to the simple question, “What is going on tonight ?”
          Here we go again. I realized that there has got to be a better way to
          do this, so we took matters into our own hands, and we have done
          something about it- Park City Insider.
        </P>
        <P>
          Like a lot of people here I’m not originally from Park City. If you
          ever sat next to me at the bar, you probably heard the incredibly
          moving, and slightly embellished (slightly mind you), tale of being
          abandoned as a baby and raised by a pack of she-wolves in Montana. I
          went on to eventually make it big in the dental floss business with
          help from Frank Zappa. It’s a tantalizing tale, which I’ll save for
          another time.
        </P>
        <P>
          I migrated to Park City in the winter of ‘80-’81, back then it wasn’t
          much more than a dying mining town with a struggling ski area. At the
          time, Main Street consisted of restaurants, boarded-up, buildings,
          empty lots, and a few bars. If you remember the Black Pearl (now 350
          Main), that should bring a smile to your face.
        </P>
        <P>
          I used to stay with my girlfriend in a two-room apartment- I use the
          term apartment loosely- above a bar with a dirt parking lot on Heber
          Ave, across from Utah Coal & Lumber, long gone now. The town was
          teetering, and Deer Valley was still a year away from opening. Since
          then, a lot has changed obviously.
        </P>
        <P>
          As I recognize the drastic changes to this town I find myself still
          not getting a very good answer to the simple question of “What is
          going on tonight?”. We’re finally answering that question in a
          comprehensive, accurate and real-time setting.
        </P>
        <P>
          Additionally, this is not just about locating events- whether it’s a
          yoga class or a concert, it’s ultimately about connecting the
          community-regardless if you live here full time or only visit
          occasionally.
        </P>
        <P>
          Park City Insider is not only about the party and good times, but we
          are also about giving back to a community that has given so much to
          all of us. We have committed to recognizing and supporting local
          nonprofits and volunteers who contribute their time and efforts to
          make Park City a better place.
        </P>

        <P>The Insider</P>
      </SplitContainer>
      <SplitContainer imageUrl={charityimg}>
        <CounterGroups>
          <CounterGroup>
            <Counter>117+</Counter>
            <Category>Volunteer Hours</Category>
          </CounterGroup>
          <CounterGroup>
            <Counter>187</Counter>
            <Category>Boxes Candy</Category>
          </CounterGroup>
        </CounterGroups>
        <Lead>
          Locals run Park City Insider, and it is important to us to give back
          to the community that fills our life with joy. Every month we donate
          our time and a portion of our profits to local non-profit.
        </Lead>
        <P>
          It’s taken me most of my life to realize what is really important to
          me. I used to believe that I needed to collect things to be happy- I
          depended upon addition to finding what I thought was the key to
          happiness. In a way I never imagined, I have learned that subtraction
          is the pathway to my sincere sense of well being- when I removed the
          things that I thought I so desperately needed, it was there behind
          everything, now in plain view, and it had always been there; just
          obscured by trivialities. It was giving back and thinking about
          someone other than myself for a change.
        </P>
        <h3>VA Nursing Home</h3>
        <P>
          Last Thanksgiving, for various reasons, I was a man without a country.
          With really nowhere to turn to I found myself volunteering at the VA
          nursing home on the VA campus in Salt Lake. My experience there has
          drastically changed my perspective on a lot of things. Since then I go
          back there for a few hours every week, in actuality, I now look for
          reasons to go more often.
        </P>
        <P>
          I have some very good friends there now. I have one, in particular,
          he’s 92, smart, articulate and a true gentleman. When he was just 19,
          in 1944 he was blown off a hill in Okinawa and still to this day
          carries shrapnel in his back. He came back after the war and became a
          school teacher, got married, raised kids and teaches me about humility
          and integrity every time I see him.
        </P>
        <P>
          He doesn’t think of himself as a hero he thinks of himself as a scared
          boy at a horrific moment in time. For a few hours a week he helps me
          think about someone besides myself--and in a very moving way, by
          giving to him- straightens my head out and the little things that
          bother me don’t really matter so much anymore.
        </P>

        <P>
          Giving back is very important to us here not only at the Insider but
          in Park City as well. Events are all good and well but we sincerely
          want to hear about your volunteer efforts so that we can highlight
          them. We also want to hear about your suggestions for local
          non-profits that we together can support.
        </P>
        <P>
          <strong>
            The meaning of life is to find your gift, the purpose of life is to
            give it away.
            <br />- William Shakespeare
          </strong>
        </P>
      </SplitContainer>
    </Page>
  </Layout>
)

export default AboutPage
